import React from "react"
import Head from "next/head"
import Layout from "components/Layout"
import MobileHome from "components/Mobile/Home"

export default function Index(props) {
  return (
    <Layout>
      <Head>
        <title>Home</title>
      </Head>

      <MobileHome {...props} />
    </Layout>
  )
}

export async function getStaticProps() {
  let res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/accomodations?featured=true`)
  const featuredAccomodations = await res.json()

  res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/accomodations/latests?limit=20`)
  const latestAccomodationsReviewed = await res.json()

  return {
    props: {
      featuredAccomodations,
      latestAccomodationsReviewed,
    },
    revalidate: 1,
  }
}
