/* eslint-disable react/prop-types */
import React from "react"
import Card from "../Card"
import Footer from "../Footer"
import { Header } from "../Header"
import { Grid, Box } from "@material-ui/core"

import css from "./home.module.scss"
// import logo from "assets/img/logo.png"

const MobileHome = (props) => {
  const { latestAccomodationsReviewed, featuredAccomodations } = props

  return (
    <>
      <Grid container className="container">
        <Header isHome content={null} />

        <Grid container style={{ overflow: "hidden", padding: 8 }}>
          <Grid item>
            <h3 className={css.gray}>In Evidenza</h3>
          </Grid>
          <Grid container spacing={1}>
            {featuredAccomodations.map((accomodation) => {
              return (
                <Grid item xs={6} sm={4} key={Math.random()}>
                  <Card {...accomodation} />
                </Grid>
              )
            })}
          </Grid>

          <Grid item>
            <h3 className={css.gray}>Le ultime recensite</h3>
          </Grid>
          <Grid container spacing={1}>
            {latestAccomodationsReviewed.map((accomodation) => {
              return (
                <Grid item xs={6} sm={4} key={Math.random()}>
                  <Card {...accomodation} />
                </Grid>
              )
            })}
          </Grid>

          <Grid item mt={2} xs={12}>
            <Box mt={2}>
              {/* <Link href="/add-accomodation">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  // startIcon={<LeaveReviewIcon />}
                >
                  Aggiungi la tua struttura
                </Button>
              </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default MobileHome
